/**********************
Sidebar
**********************/

.sidebar {
	flex: 1;
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	margin-left: 2rem;
	margin-top: 1rem;
	max-height: 100vw;
}

.sidebar h2 {
	margin: 0.2rem auto;
	border-bottom: 2px solid rgba(50, 50, 50, 0.6);
	width: 60%;
}

.img-headshot {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	border: 6px double rgba(223, 139, 14, 0.5);
	height: 40%;
	max-height: 20vh;
	margin-bottom: 1rem;
	z-index: 500;
	display: none;
}

.sidebar--deets {
	background: rgba(26, 15, 15, 0.2);
	border-radius: 15px;
	width: 100%;
	color: white;
}

.skills {
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
}

.skill-title {
	margin: 0;
	padding: 0;
	font-size: 0.8rem;
}

.skills div {
	padding: 0.4rem;
}

.skills i {
	font-size: 3rem;
}

@media only screen and (max-width: 600px) {
	.sidebar {
		display: none;
	}
}
