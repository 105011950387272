.container-contact {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	color: white;
	overflow: auto;
}

.contact-blurb {
	font-size: calc(1rem + 1vh);
	margin: 2rem;
}

.form-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: calc(0.75rem + 1vh);
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
}
.form-container form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 870px;
	background: rgba(150, 150, 150, 0.4);
	border-radius: 15px;
	margin: 2rem 0;
	padding: 2rem;
}

.form-container form label {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0.5rem;
}

.form-container form input,
#subject {
	font-size: calc(0.25rem + 1vh);
	margin: 0.2rem;
	width: 500px;
	padding: 0.5rem;
	border: none;
	border-radius: 10px;
}

.submit-btn {
	cursor: pointer;
	width: 50%;
	max-width: 200px;
	outline: none;
	border: none;
	border-radius: 10px;
	padding: 0.5rem;
	background-color: green;
	color: white;
	font-weight: bolder;
}

.submit-btn:hover,
.submit-btn:focus {
	opacity: 0.7;
	border: 1px solid rgba(50, 50, 50, 0.7);
}

.email-sent {
	width: 60%;
	bottom: 15px;
	border: 2px solid black;
	border-radius: 10px;
	transition: all 0.3s ease;
	color: black;
	background-color: blanchedalmond;
}

.sent {
	opacity: 0.6;
	pointer-events: none;
}

.hidden {
	display: none;
	transition: all 0.3s ease;
}

.error {
	background-color: salmon;
	opacity: 0.6;
}

@media only screen and (max-width: 600px) {
	.form-container form label {
		width: 100%;
	}

	.form-container form label input,
	#subject {
		width: 100%;
	}

	.contact-blurb {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	.container-contact {
		width: 100%;
	}
	.form-container form {
		width: 80vw;
	}

	#subject {
		width: 90%;
	}
}
