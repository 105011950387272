body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	--text-head: 'RocknRoll One', sans-serif;
	--text-body: 'Livvic', sans-serif;
}

.App {
	background-image: repeating-linear-gradient(
			45deg,
			rgba(224, 123, 50, 0.06) 0px,
			rgba(224, 123, 50, 0.06) 2px,
			transparent 2px,
			transparent 4px
		),
		linear-gradient(45deg, rgb(92, 92, 100), rgb(23, 23, 27));
	font-size: calc(0.5rem + 1vh);
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

:focus {
	outline: none;
}

a:hover,
a:focus {
	transform: scale(1.05);
	opacity: 0.7;
}

/**********************
	Scrollbars
**********************/

/* width */
::-webkit-scrollbar {
	width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--grey);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: green;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/**********************
	Main Content
**********************/
.container-main {
	position: relative;
	padding: 0.5rem;
	background: linear-gradient(
		to bottom right,
		rgba(50, 50, 50, 0.8),
		rgba(100, 100, 100, 0.8)
	);
	position: relative;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100vw;
	/* min-height: 100vh; */
	box-shadow: 10px 10px 20px rgba(50, 50, 50, 0.8);
	font-family: 'Livvic', sans-serif;
	font-family: var(--text-body);
}

@media only screen and (max-width: 600px) {
	.container-main {
		border-left: none;
		margin: 0;
		width: 100vw;
		min-height: 100vh;
	}
}

/**********************
NavBar
**********************/

nav {
	position: fixed;
	top: 0;
	width: 100%;
	font-size: calc(0.5rem + 0.75vh);
	background: rgba(50, 50, 50, 1);
	z-index: 100;
}

nav ul {
	width: 100%;
	padding: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	list-style: none;
	font-family: var(--text-head);
	margin-right: 0.5rem;
}

nav ul li {
	padding: 0.5rem;
	margin-right: 0.2rem;
	cursor: pointer;
	margin: 0.4rem;
}

nav ul li a {
	text-decoration: none;
	color: rgb(150, 150, 150);
	transition: color 0.3s ease;
	outline: 0;
}

.active {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 8px solid rgba(223, 139, 14, 0.9);
	pointer-events: none;
	color: rgb(255, 255, 255);
}

nav ul li a:not(.active):hover {
	color: rgba(223, 139, 14, 0.9);
	opacity: 0.6;
	border-left: 2px solid transparent;
	border-right: 2px solid transparent;
	border-bottom: 4px solid rgba(223, 139, 14, 0.9);
}

@media only screen and (max-width: 600px) {
	.nav {
		width: 100%;
	}

	.nav ul {
		font-size: smaller;
		background: rgba(23, 23, 27, 1);
		justify-content: center;
		z-index: 800;
	}

	.active {
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		border-bottom: 2px solid rgba(223, 139, 14, 0.9);
	}
}

footer .social-links {
	margin: 0 1rem;
}

footer {
	position: fixed;
	width: 100%;
	background: rgba(50, 50, 50, 1);
	bottom: 0;
	right: 0;
	padding: 0 2rem;
	text-align: right;
	color: white;
	font-size: calc(0.5rem + 0.5vh);
}

.footer-link {
	font-size: 1.2rem;
	color: rgba(223, 139, 14, 0.7);
	padding: 0.2rem;
	/* outline: none; */
}

@media only screen and (max-width: 600px) {
	footer {
		margin: 0;
		background-color: black;
		width: 100vw;
		text-align: right;
	}
}

/**********************
Sidebar
**********************/

.sidebar {
	flex: 1 1;
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	margin-left: 2rem;
	margin-top: 1rem;
	max-height: 100vw;
}

.sidebar h2 {
	margin: 0.2rem auto;
	border-bottom: 2px solid rgba(50, 50, 50, 0.6);
	width: 60%;
}

.img-headshot {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	border: 6px double rgba(223, 139, 14, 0.5);
	height: 40%;
	max-height: 20vh;
	margin-bottom: 1rem;
	z-index: 500;
	display: none;
}

.sidebar--deets {
	background: rgba(26, 15, 15, 0.2);
	border-radius: 15px;
	width: 100%;
	color: white;
}

.skills {
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
}

.skill-title {
	margin: 0;
	padding: 0;
	font-size: 0.8rem;
}

.skills div {
	padding: 0.4rem;
}

.skills i {
	font-size: 3rem;
}

@media only screen and (max-width: 600px) {
	.sidebar {
		display: none;
	}
}

/**********************
Main
**********************/
@keyframes grow {
	0% {
		transform: rotate(0deg);
		font-size: 0.3rem;
		margin: 0.2rem;
	}
	20% {
		transform: rotate(-15deg);
		color: rgba(223, 139, 14, 0.7);
	}
	30% {
		transform: rotate(5deg);
		font-size: 1rem;
		margin: 0.4rem;
	}
	50% {
		transform: rotate(-15deg);
		color: rgba(223, 139, 14, 0.7);
	}
	60% {
		transform: rotate(5deg);
		color: rgba(223, 139, 14, 0.7);
		font-size: 2rem;
		margin: 0.7rem;
	}
	90% {
		transform: rotate(-15deg);
		color: rgba(223, 139, 14, 0.7);
	}
	100% {
		transform: rotate(0deg);
		color: white;
	}
}

header {
	/* position: absolute; */
	top: 1rem;
	font-size: calc(1rem + 1vh);
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
	color: rgb(255, 255, 255);
}

header h1 {
	font-family: var(--text-head);
	animation-name: grow;
	animation-duration: 3s;
}

.container-home {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	/* min-height: 50vw; */
	overflow: auto;
}

.main {
	flex: 4 1;
	width: 70%;
	color: rgb(200, 200, 200);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	text-align: center;
	margin-top: 5vh;
}

.tagline {
	position: relative;
	font-size: calc(1.2rem + 0.5vh);
	z-index: 600;
}

.about-blurb {
	max-width: 800px;
	margin: 1rem -6rem;
}

.home-social-links {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 0 0.5rem 0;
}

.home-social-links a {
	padding: 0 1rem;
	outline: none;
	color: white;
	text-decoration: none;
}

.home-social-links a:hover {
	opacity: 0.8;
}

.home-social-links i {
	margin: 0.4rem;
	position: relative;
	top: 10%;
	font-size: calc(1rem + 1vh);
}

.action-btn {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	padding: 0.4rem;
	margin: 1rem;
	color: black;
	font-weight: bolder;
	border: 2px solid rgba(223, 139, 14, 0.9);
	background-color: rgba(223, 139, 14, 0.9);
	border-radius: 15px;
	outline: none;
	width: 30%;
	text-align: center;
	transition: all 0.3s ease;
}

.action-btn:hover {
	opacity: 0.7;
	border: 2px solid rgba(223, 139, 14, 0.9);
}

.home-bio {
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
}

@media only screen and (max-width: 600px) {
	header {
		border-top: none;
	}
	header h1 {
		animation: none;
		font-size: 1.2rem;
	}
	.main {
		margin-top: 1rem;
		max-width: 90vw;
	}

	.btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		margin: 0;
		max-width: 400px;
	}

	.action-btn {
		width: 200px;
		font-size: 0.75rem;
		padding: 0.7rem;
	}

	.about-blurb {
		font-size: 0.9rem;
	}
}

:root {
	--orange: rgba(223, 139, 14, 0.7);
	--grey: rgba(92, 92, 100, 0.6);
}

.container-port {
	color: white;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	align-items: center;
	color: white;
	margin: 2rem 0;
	padding: 1rem 0.5rem;
	overflow: hidden;
}

.port-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 1rem 0;
	width: 100%;
}

.port-item {
	/* flex-basis: 10%; */
	max-width: 500px;
	min-width: 400px;
	width: 30vw;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	font-size: calc(1rem + 1vh);
	border: 2px solid rgba(92, 92, 100, 0.6);
	border: 2px solid var(--grey);
	border-radius: 5px;
	box-shadow: 2px 5px 5px rgba(92, 92, 100, 0.6);
	box-shadow: 2px 5px 5px var(--grey);
	margin: 0.5rem;
}

/* .port-top {
	width: 100%;
}
.port-bottom {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	width: 80%;
}

.port-right {
	flex-basis: 50%;
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
} */

/****************
Titlecard
****************/

.port-item-title {
	padding: 0.5rem;
	background-color: rgba(92, 92, 100, 0.6);
	background-color: var(--grey);
	width: 100%;
	font-family: var(--text-head);
}

.port-item-title h3 {
	font-size: 1.2rem;
	padding: 0.4rem;
	margin: 0.2rem 0;
}

.port-item-title h5 {
	font-family: var(--text-body);
	font-size: 0.75rem;
	padding: 0.4rem;
	margin: 0 0 0 0.2rem;
}

/****************
Tags
****************/

.port-item-tagList {
	display: flex;
	flex-wrap: wrap;
	margin: 0.5em 0;
	padding: 0 1rem;
	justify-content: center;
	list-style: none;
}

.port-item-tagList li {
	background: rgba(223, 139, 14, 0.7);
	background: var(--orange);
	color: black;
	text-transform: uppercase;
	font-size: 0.7rem;
	font-weight: bold;
	min-width: 50px;
	border-radius: 5px;
	padding: 0.1rem 0.2rem;
	margin: 0.1rem 0.2rem;
}

/****************
Image
****************/

.port-item-img {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	height: 300px;
	transition: all 200ms ease-in-out;
}

.port-item-img a {
	color: white;
	text-decoration: none;
}

.port-item-img-overlay {
	position: absolute;
	transform: scale(0);
	background-color: rgba(50, 50, 50, 0.8);
	transition: all 300ms ease-in-out;
	border-radius: 5px;
}

.port-item-img-overlay i:hover {
	color: rgba(223, 139, 14, 0.7);
	color: var(--orange);
}
.port-item-img-overlay i {
	padding: 1rem;
}

.port-item-img:hover {
	/* transform: scale(1.1); */
	transform: scale(0.8);
}

.port-item-img:hover .port-item-img-overlay {
	opacity: 1;
	transform: scale(1.5);
}

/****************
Links
****************/
.port-item-hrefs {
	display: flex;
	justify-content: center;
	margin-bottom: 0.5rem;
}
.port-item-hrefs a {
	text-decoration: none;
	font-size: 0.9rem;
	margin: 1rem;
	padding: 0.5rem 3rem;
	color: var(--white);
	border: 2px solid rgba(223, 139, 14, 0.7);
	border: 2px solid var(--orange);
	border-radius: 15px;
}
.port-item-hrefs a:hover,
.port-item-hrefs a:focus {
	background: rgba(223, 139, 14, 0.7);
	background: var(--orange);
	font-weight: bold;
	opacity: 0.7;
}

/****************
Description
****************/

.port-desc {
	padding: 1rem;
	width: 100%;
}

.port-item-desc {
	font-size: calc(0.5rem + 1vh);
	height: 20%;
	margin: 0.5rem 1rem;
}
.port-item-desc p {
	white-space: pre-wrap;
}

@media only screen and (max-width: 800px) {
	.container-port {
		width: 100%;
		padding: 0;
	}

	.port-item {
		flex-basis: 100%;
		justify-content: center;
	}

	.port-top {
		width: 100%;
	}

	.port-left {
		display: none;
	}
	.port-right {
		flex-basis: 100%;
	}

	.port-item-title {
		padding: 0.2rem;
	}

	.port-item-hrefs {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.port-item-hrefs a {
		background: rgba(223, 139, 14, 0.7);
		background: var(--orange);
		font-weight: bolder;
		width: 50%;
		margin: 0.5rem;
	}
}

.container-contact {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	color: white;
	overflow: auto;
}

.contact-blurb {
	font-size: calc(1rem + 1vh);
	margin: 2rem;
}

.form-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: calc(0.75rem + 1vh);
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
}
.form-container form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 870px;
	background: rgba(150, 150, 150, 0.4);
	border-radius: 15px;
	margin: 2rem 0;
	padding: 2rem;
}

.form-container form label {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0.5rem;
}

.form-container form input,
#subject {
	font-size: calc(0.25rem + 1vh);
	margin: 0.2rem;
	width: 500px;
	padding: 0.5rem;
	border: none;
	border-radius: 10px;
}

.submit-btn {
	cursor: pointer;
	width: 50%;
	max-width: 200px;
	outline: none;
	border: none;
	border-radius: 10px;
	padding: 0.5rem;
	background-color: green;
	color: white;
	font-weight: bolder;
}

.submit-btn:hover,
.submit-btn:focus {
	opacity: 0.7;
	border: 1px solid rgba(50, 50, 50, 0.7);
}

.email-sent {
	width: 60%;
	bottom: 15px;
	border: 2px solid black;
	border-radius: 10px;
	transition: all 0.3s ease;
	color: black;
	background-color: blanchedalmond;
}

.sent {
	opacity: 0.6;
	pointer-events: none;
}

.hidden {
	display: none;
	transition: all 0.3s ease;
}

.error {
	background-color: salmon;
	opacity: 0.6;
}

@media only screen and (max-width: 600px) {
	.form-container form label {
		width: 100%;
	}

	.form-container form label input,
	#subject {
		width: 100%;
	}

	.contact-blurb {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	.container-contact {
		width: 100%;
	}
	.form-container form {
		width: 80vw;
	}

	#subject {
		width: 90%;
	}
}

