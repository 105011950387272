:root {
	--orange: rgba(223, 139, 14, 0.7);
	--grey: rgba(92, 92, 100, 0.6);
}

.container-port {
	color: white;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	align-items: center;
	color: white;
	margin: 2rem 0;
	padding: 1rem 0.5rem;
	overflow: hidden;
}

.port-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 1rem 0;
	width: 100%;
}

.port-item {
	/* flex-basis: 10%; */
	max-width: 500px;
	min-width: 400px;
	width: 30vw;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	font-size: calc(1rem + 1vh);
	border: 2px solid var(--grey);
	border-radius: 5px;
	box-shadow: 2px 5px 5px var(--grey);
	margin: 0.5rem;
}

/* .port-top {
	width: 100%;
}
.port-bottom {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	width: 80%;
}

.port-right {
	flex-basis: 50%;
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
} */

/****************
Titlecard
****************/

.port-item-title {
	padding: 0.5rem;
	background-color: var(--grey);
	width: 100%;
	font-family: var(--text-head);
}

.port-item-title h3 {
	font-size: 1.2rem;
	padding: 0.4rem;
	margin: 0.2rem 0;
}

.port-item-title h5 {
	font-family: var(--text-body);
	font-size: 0.75rem;
	padding: 0.4rem;
	margin: 0 0 0 0.2rem;
}

/****************
Tags
****************/

.port-item-tagList {
	display: flex;
	flex-wrap: wrap;
	margin: 0.5em 0;
	padding: 0 1rem;
	justify-content: center;
	list-style: none;
}

.port-item-tagList li {
	background: var(--orange);
	color: black;
	text-transform: uppercase;
	font-size: 0.7rem;
	font-weight: bold;
	min-width: 50px;
	border-radius: 5px;
	padding: 0.1rem 0.2rem;
	margin: 0.1rem 0.2rem;
}

/****************
Image
****************/

.port-item-img {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	height: 300px;
	transition: all 200ms ease-in-out;
}

.port-item-img a {
	color: white;
	text-decoration: none;
}

.port-item-img-overlay {
	position: absolute;
	transform: scale(0);
	background-color: rgba(50, 50, 50, 0.8);
	transition: all 300ms ease-in-out;
	border-radius: 5px;
}

.port-item-img-overlay i:hover {
	color: var(--orange);
}
.port-item-img-overlay i {
	padding: 1rem;
}

.port-item-img:hover {
	/* transform: scale(1.1); */
	transform: scale(0.8);
}

.port-item-img:hover .port-item-img-overlay {
	opacity: 1;
	transform: scale(1.5);
}

/****************
Links
****************/
.port-item-hrefs {
	display: flex;
	justify-content: center;
	margin-bottom: 0.5rem;
}
.port-item-hrefs a {
	text-decoration: none;
	font-size: 0.9rem;
	margin: 1rem;
	padding: 0.5rem 3rem;
	color: var(--white);
	border: 2px solid var(--orange);
	border-radius: 15px;
}
.port-item-hrefs a:hover,
.port-item-hrefs a:focus {
	background: var(--orange);
	font-weight: bold;
	opacity: 0.7;
}

/****************
Description
****************/

.port-desc {
	padding: 1rem;
	width: 100%;
}

.port-item-desc {
	font-size: calc(0.5rem + 1vh);
	height: 20%;
	margin: 0.5rem 1rem;
}
.port-item-desc p {
	white-space: pre-wrap;
}

@media only screen and (max-width: 800px) {
	.container-port {
		width: 100%;
		padding: 0;
	}

	.port-item {
		flex-basis: 100%;
		justify-content: center;
	}

	.port-top {
		width: 100%;
	}

	.port-left {
		display: none;
	}
	.port-right {
		flex-basis: 100%;
	}

	.port-item-title {
		padding: 0.2rem;
	}

	.port-item-hrefs {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.port-item-hrefs a {
		background: var(--orange);
		font-weight: bolder;
		width: 50%;
		margin: 0.5rem;
	}
}
