footer .social-links {
	margin: 0 1rem;
}

footer {
	position: fixed;
	width: 100%;
	background: rgba(50, 50, 50, 1);
	bottom: 0;
	right: 0;
	padding: 0 2rem;
	text-align: right;
	color: white;
	font-size: calc(0.5rem + 0.5vh);
}

.footer-link {
	font-size: 1.2rem;
	color: rgba(223, 139, 14, 0.7);
	padding: 0.2rem;
	/* outline: none; */
}

@media only screen and (max-width: 600px) {
	footer {
		margin: 0;
		background-color: black;
		width: 100vw;
		text-align: right;
	}
}
