:root {
	--text-head: 'RocknRoll One', sans-serif;
	--text-body: 'Livvic', sans-serif;
}

.App {
	background-image: repeating-linear-gradient(
			45deg,
			rgba(224, 123, 50, 0.06) 0px,
			rgba(224, 123, 50, 0.06) 2px,
			transparent 2px,
			transparent 4px
		),
		linear-gradient(45deg, rgb(92, 92, 100), rgb(23, 23, 27));
	font-size: calc(0.5rem + 1vh);
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

:focus {
	outline: none;
}

a:hover,
a:focus {
	transform: scale(1.05);
	opacity: 0.7;
}

/**********************
	Scrollbars
**********************/

/* width */
::-webkit-scrollbar {
	width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--grey);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: green;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/**********************
	Main Content
**********************/
.container-main {
	position: relative;
	padding: 0.5rem;
	background: linear-gradient(
		to bottom right,
		rgba(50, 50, 50, 0.8),
		rgba(100, 100, 100, 0.8)
	);
	position: relative;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100vw;
	/* min-height: 100vh; */
	box-shadow: 10px 10px 20px rgba(50, 50, 50, 0.8);
	font-family: var(--text-body);
}

@media only screen and (max-width: 600px) {
	.container-main {
		border-left: none;
		margin: 0;
		width: 100vw;
		min-height: 100vh;
	}
}
