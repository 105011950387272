/**********************
NavBar
**********************/

nav {
	position: fixed;
	top: 0;
	width: 100%;
	font-size: calc(0.5rem + 0.75vh);
	background: rgba(50, 50, 50, 1);
	z-index: 100;
}

nav ul {
	width: 100%;
	padding: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	list-style: none;
	font-family: var(--text-head);
	margin-right: 0.5rem;
}

nav ul li {
	padding: 0.5rem;
	margin-right: 0.2rem;
	cursor: pointer;
	margin: 0.4rem;
}

nav ul li a {
	text-decoration: none;
	color: rgb(150, 150, 150);
	transition: color 0.3s ease;
	outline: 0;
}

.active {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 8px solid rgba(223, 139, 14, 0.9);
	pointer-events: none;
	color: rgb(255, 255, 255);
}

nav ul li a:not(.active):hover {
	color: rgba(223, 139, 14, 0.9);
	opacity: 0.6;
	border-left: 2px solid transparent;
	border-right: 2px solid transparent;
	border-bottom: 4px solid rgba(223, 139, 14, 0.9);
}

@media only screen and (max-width: 600px) {
	.nav {
		width: 100%;
	}

	.nav ul {
		font-size: smaller;
		background: rgba(23, 23, 27, 1);
		justify-content: center;
		z-index: 800;
	}

	.active {
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		border-bottom: 2px solid rgba(223, 139, 14, 0.9);
	}
}
