/**********************
Main
**********************/
@keyframes grow {
	0% {
		transform: rotate(0deg);
		font-size: 0.3rem;
		margin: 0.2rem;
	}
	20% {
		transform: rotate(-15deg);
		color: rgba(223, 139, 14, 0.7);
	}
	30% {
		transform: rotate(5deg);
		font-size: 1rem;
		margin: 0.4rem;
	}
	50% {
		transform: rotate(-15deg);
		color: rgba(223, 139, 14, 0.7);
	}
	60% {
		transform: rotate(5deg);
		color: rgba(223, 139, 14, 0.7);
		font-size: 2rem;
		margin: 0.7rem;
	}
	90% {
		transform: rotate(-15deg);
		color: rgba(223, 139, 14, 0.7);
	}
	100% {
		transform: rotate(0deg);
		color: white;
	}
}

header {
	/* position: absolute; */
	top: 1rem;
	font-size: calc(1rem + 1vh);
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
	color: rgb(255, 255, 255);
}

header h1 {
	font-family: var(--text-head);
	animation-name: grow;
	animation-duration: 3s;
}

.container-home {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	/* min-height: 50vw; */
	overflow: auto;
}

.main {
	flex: 4;
	width: 70%;
	color: rgb(200, 200, 200);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	text-align: center;
	margin-top: 5vh;
}

.tagline {
	position: relative;
	font-size: calc(1.2rem + 0.5vh);
	z-index: 600;
}

.about-blurb {
	max-width: 800px;
	margin: 1rem -6rem;
}

.home-social-links {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 0 0.5rem 0;
}

.home-social-links a {
	padding: 0 1rem;
	outline: none;
	color: white;
	text-decoration: none;
}

.home-social-links a:hover {
	opacity: 0.8;
}

.home-social-links i {
	margin: 0.4rem;
	position: relative;
	top: 10%;
	font-size: calc(1rem + 1vh);
}

.action-btn {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	padding: 0.4rem;
	margin: 1rem;
	color: black;
	font-weight: bolder;
	border: 2px solid rgba(223, 139, 14, 0.9);
	background-color: rgba(223, 139, 14, 0.9);
	border-radius: 15px;
	outline: none;
	width: 30%;
	text-align: center;
	transition: all 0.3s ease;
}

.action-btn:hover {
	opacity: 0.7;
	border: 2px solid rgba(223, 139, 14, 0.9);
}

.home-bio {
	border-bottom: 4px solid rgba(223, 139, 14, 0.7);
	border-right: 6rem solid transparent;
	border-left: 6rem solid transparent;
	border-top: 4px solid rgba(223, 139, 14, 0.7);
}

@media only screen and (max-width: 600px) {
	header {
		border-top: none;
	}
	header h1 {
		animation: none;
		font-size: 1.2rem;
	}
	.main {
		margin-top: 1rem;
		max-width: 90vw;
	}

	.btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		margin: 0;
		max-width: 400px;
	}

	.action-btn {
		width: 200px;
		font-size: 0.75rem;
		padding: 0.7rem;
	}

	.about-blurb {
		font-size: 0.9rem;
	}
}
